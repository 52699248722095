import { Box, Grid, Typography } from "@mui/material";
import '../styles/ProcessStyles.css';
import ProcessBox from "../components/ProccessBox";
import { useEffect } from "react";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';


const Process = () => {
    function isElementInViewport(element: any) {
        var rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    function onScroll() {
        var allElements: NodeListOf<Element>[] = [];
        var p1 = document.querySelectorAll('.proc1');
        var p2 = document.querySelectorAll('.proc2');
        var p3 = document.querySelectorAll('.proc3');
        var p4 = document.querySelectorAll('.proc4');
        var p5 = document.querySelectorAll('.proc5');
        allElements.push(p1, p2, p3, p4, p5)

        allElements.forEach(elements => {
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                if (isElementInViewport(element)) {
                    element.classList.add('animate');
                }
            }
        });
    }

    useEffect(() => {
        if (window.location.pathname.includes('process'))
            onScroll()
    })

    useEffect(() => {
        document.addEventListener('DOMContentLoaded', onScroll);
        document.addEventListener('scroll', onScroll);
    })

    return (
        <Box className='process'>
            <div>
                <Typography
                    textAlign={'center'}
                    fontFamily={'DevilBold'}
                    variant="h3"
                >
                    The Process...
                </Typography>
                <div className="pBox">
                    <Grid className="proc" container rowSpacing={4} spacing={5} width={'100%'} justifyContent={'center'} marginTop={'5%'} textAlign={'center'}>
                        <Grid item className="proc1" xs={12} md={2} lg={2}>
                            <ProcessBox
                                icon={<HandshakeOutlinedIcon sx={{ color: '#fff', width: '100px', height: '100px' }} />}
                                title='Engage!'
                                text='You start the conversation, and we begin creating magic...'
                            />
                        </Grid>
                        <Grid item className="proc2" xs={12} md={2} lg={2}>
                            <ProcessBox
                                icon={<ExploreOutlinedIcon sx={{ color: '#fff', width: '100px', height: '100px' }} />}
                                title="Explore."
                                text='We dive deep into your market, syncing with your business groove...'
                            />
                        </Grid>
                        <Grid item className="proc3" xs={12} md={2} lg={2}>
                            <ProcessBox
                                icon={<CreateOutlinedIcon sx={{ color: '#fff', width: '100px', height: '100px' }} />}
                                title='Shape.'
                                text='Project takes form, with your input as our creative fuel...'
                            />
                        </Grid>
                        <Grid item className="proc4" xs={12} md={2} lg={2}>
                            <ProcessBox
                                icon={<RocketOutlinedIcon sx={{ color: '#fff', width: '100px', height: '100px' }} />}
                                title='Launch.'
                                text='Polished and perfected, the product gears up for its online debut.'
                            />
                        </Grid>
                        <Grid item className="proc5" xs={12} md={2} lg={2}>
                            <ProcessBox
                                icon={<AutoAwesomeOutlinedIcon sx={{ color: '#fff', width: '100px', height: '100px' }} />}
                                title='Amaze!'
                                text='Prepare for a jaw-dropping experience as remarkable results unfold. Get set to be amazed!'
                            />
                        </Grid>
                    </Grid>
                </div>

            </div>
        </Box>
    )
}

export default Process