import { Box, Icon, Typography } from "@mui/material"
import '../styles/styles.css'

type ProcessProps = {
    title: string,
    text: string,
    icon: any
}
const ProcessBox = (props: ProcessProps) => {
    return (
        <Box
            className='processBox'
        // sx={{
        //     backgroundColor: '#202823',
        //     height: '400px',
        //     width: '80%',
        //     borderRadius: '10px',
        //     border: '4px solid #202823',
        //     transitionDuration: '1s',
        //     '&:hover': {
        //         backgroundColor: 'transparent',
        //         boxShadow: '10px 10px'
        //     }
        // }}
        >
            <div style={{ marginTop: '10%' }}>
                {props.icon}
            </div>
            <Typography color='#fff' variant='h3' fontFamily={'Romance'} marginTop={'10%'}>{props.title}</Typography>
            <Typography className='ptext' color='#fff' margin='5%'>{props.text}</Typography>
        </Box>
    )
}

export default ProcessBox