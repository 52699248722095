import { Box, Button, Typography } from "@mui/material"
import './../styles/AboutStyles.css';
import { useEffect } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const About = () => {
    function isInViewport(element: any) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    function handleScroll() {
        const element = document.querySelectorAll('.aboutHead');
        element.forEach(element => {
            if (isInViewport(element) && !element.classList.contains('animate')) {
                element.classList.add('animate');
            }
        });
    }

    useEffect(() => {
        if (window.location.pathname.includes('about'))
            handleScroll()
    })

    useEffect(() => {
        document.addEventListener('DOMContentLoaded', handleScroll);
        document.addEventListener('scroll', handleScroll);
    })

    return (
        <Box sx={{ backgroundColor: '#202823', paddingTop: '0px', paddingBottom: '0px 0px 0px 0px' }} className='about' minHeight={'100vh'}>
            <div style={{ position: 'absolute' }}>
                <Box display={'block'} textAlign={'center'}>
                    <div className="aboutHead">
                        <Box sx={{ width: '60%', margin: '5% 18%', height: '50px', padding: 5 }}>
                            <InfoOutlinedIcon sx={{ position: 'absolute', color: '#89B43D', width: '150px', height: '150px', opacity: '0.3', marginLeft: '8%' }} />
                            <Typography className="title" fontFamily={'DevilBold'} variant='h2' marginBottom={10} color={'#89B43D'}>Your Website.<br /> Your Story.</Typography>
                        </Box>
                    </div>
                    <Typography fontFamily={'Romance'} color={'#fff'} variant="h4" textAlign={'center'}>
                        <div className="aboutDesc" >
                            We design and develop amazing websites that capture the attention of your visitors.
                            We ensure that your online presence is smooth, user-friendly, and attractive.
                            We tell your story to the world in the <b style={{ fontFamily: 'DevilBold' }}>bold</b> way.
                        </div>
                    <Button href="/#contact" sx={{color:"#89B43D", border:'2px solid #89B43D', '&:hover': {color:"#fff", border:'2px solid #fff'}}}>What's Your Story?</Button>
                    </Typography>
                    <svg viewBox="0 0 500 200" preserveAspectRatio="xMinYMin meet">
                        <path d="M0,120 C100,80 150,250 400,0 L100,00 L0,0 Z" style={{ stroke: 'none', fill: '#566e5f' }}></path>
                        <path d="M0,0 C50,-50 250,350 1000,-50, L500,00 L0,0 Z" style={{ stroke: 'none', fill: '#c0d1c6' }}></path>
                        <path d="M0,90 C250,10 250,300 600,10 L100,00 L0,0 Z" style={{ stroke: 'none', fill: '#3a4a40' }}></path>
                        <path d="M0,70 C150,200 250,0 500,100 L500,00 L0,0 Z" style={{ stroke: 'none', fill: '#202823' }}></path>
                    </svg>
                </Box>
            </div>
        </Box>
    )
}

export default About