import './App.css';
import Layout from './pages/Layout';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#202823',
        },
        secondary: {
            main: '#18036d'
        }
    },
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
        allVariants: {
            color: '#202823',
        },
        h4: {
            fontFamily: [
                'Garamond',
            ].join(','),
        },
        button: {
            textTransform: 'none'
        }
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Layout />
        </ThemeProvider>
    );
}

export default App;
