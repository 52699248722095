import { Box, Button, Paper, Snackbar, TextField, TextareaAutosize, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import './../styles/ContactStyles.css';
import { AlternateEmail, Phone, Place } from "@mui/icons-material";

const Contact = () => {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [openSnack, setOpenSnack] = useState(false);

    const submit = () => {
        setOpenSnack(true)
        setName('')
        setEmail('')
        setMessage('')
    }

    const closeSnack = () => {
        setOpenSnack(false)
    }

    function isInViewport(element: any) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    function onScroll() {
        var element = document.querySelectorAll('.contactBox');
        element.forEach(element => {
            if (isInViewport(element) && !element.classList.contains('animate')) {
                element.classList.add('animate');
            }
        });
    }

    useEffect(() => {
        document.addEventListener('DOMContentLoaded', onScroll);
        document.addEventListener('scroll', onScroll);
    })

    return (
        <Box className='contact'>
            <Typography variant="h4">
                Get In Touch.
            </Typography>
            <div>
                <Box className='contactInfo'>
                    <Typography fontFamily={'Montserrat'} fontWeight={'bold'}>Contact.</Typography>
                    <Typography margin={'10px'} paddingLeft={'10px'}><AlternateEmail color="primary" fontSize="small" sx={{ marginRight: '10px', marginBottom: '-5px' }} />info@boldwebnyc.com</Typography>
                    <Typography margin={'10px'} paddingLeft={'10px'}><Phone color="primary" fontSize="small" sx={{ marginRight: '10px', marginBottom: '-5px' }} />(646) 685-3409</Typography>
                    <Typography marginTop={'40px'} fontFamily={'Montserrat'} fontWeight={'bold'}>Location.</Typography>
                    <Typography margin={'10px'} paddingLeft={'10px'}><Place color="primary" fontSize="small" sx={{ marginRight: '10px', marginBottom: '-5px' }} />1314 46th Street, Suite A3</Typography>
                    <Typography margin={'10px'} paddingLeft={'40px'}>Brooklyn, NY, 11219</Typography>
                </Box>
            </div>
            <Box sx={{ width: '30vw', height: '100vh', backgroundColor: '#89B43D' }}>
                <div className="contactDiv">
                    <Paper className="contactBox" elevation={24} sx={{ borderRadius: '10px 80px 80px 10px' }}>
                        <Typography className='contactStart'>Start The Conversation...</Typography>
                        <div style={{ display: 'grid' }}>
                            <TextField
                                value={name}
                                placeholder='Name'
                                size="small"
                                onChange={(e) => setName(e.target.value)}
                                sx={{ margin: '2% auto', width: '93%' }}
                            />
                            <TextField
                                value={email}
                                placeholder='Email'
                                size="small"
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{ margin: '2% auto', width: '93%' }}
                            />
                            <TextareaAutosize
                                placeholder="Message"
                                value={message}
                                minRows={3}
                                onChange={(e) => setMessage(e.target.value)}
                                style={{
                                    border: '1px solid lightgrey',
                                    lineHeight: '1.5',
                                    padding: '10px',
                                    fontFamily: 'Montserrat',
                                    borderRadius: '5px',
                                    width: '90%',
                                    margin: '2% auto',
                                    fontSize: '16px',
                                }}
                            />
                            <Button
                                onClick={submit}
                                variant={'contained'}
                                sx={{
                                    margin: 2,
                                    borderRadius: '50px',
                                    transition: '1.5s',
                                    '&:hover': {
                                        borderRadius: '5px',
                                        backgroundColor: '#89B43D',
                                        // border: '2px solid #202823',
                                        color: '#fff'
                                    }
                                }}
                            >Send Message
                            </Button>
                        </div>
                    </Paper>
                </div>
            </Box>
        </Box >
    )
}

export default Contact