import { Box, Button, Chip, Grid, Typography, useMediaQuery } from "@mui/material"
import '../styles/ShowroomStyles.css';

const Showroom = () => {

    const ShowroomItem = (props: any) => {
        return (
            <div>
                <div className='imgContainer'>
                    <img className="image" src={props.image} rel="grants" />
                    <div className="middle">
                        <Button sx={{ backgroundColor: '#89B43D', color: '#fff', width: '100px' }}>Visit</Button>
                    </div>
                </div>
                <Typography variant="h3" fontWeight={'bold'} fontFamily={'Romance'} sx={{ color: '#fff' }}>{props.title}</Typography>
                <Typography fontFamily={'Montserrat'} sx={{ color: '#fff' }}>{props.subtitle}</Typography>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    {props.label1 && <Chip label={props.label1} sx={{ margin: '5px', borderRadius: '5px', backgroundColor: 'transparent', border: '2px solid #fff', color: '#fff' }} />}
                    {props.label2 && <Chip label={props.label2} sx={{ margin: '5px', borderRadius: '5px', backgroundColor: 'transparent', border: '2px solid #fff', color: '#fff' }} />}
                </div>
            </div>
        )
    }
    return (
        <Box className='box'>
            <Typography variant="h2" textAlign={'center'} fontFamily={'DevilBold'} sx={{ color: '#89B43D' }}>Latest Work</Typography>
            <Grid className="showGrid" container spacing={6} rowSpacing={20} padding={5} justifyContent={'center'} sx={{ flexDirection: { xs: "column", md: "row" } }}>
                <Grid item xs={12} md={4}>
                    <ShowroomItem
                        title='Brim Grant Consulting'
                        subtitle='Grant Writing and Management'
                        label1='Web Design'
                        label2='Branding'
                        image='grantsnew.jpg'
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ShowroomItem
                        title='Rent26'
                        subtitle='Property Rental Agency'
                        label1='Web Design'
                        label2='Mobile App'
                        image='rentnew.jpg'
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ShowroomItem
                        title='Pixel Crew NYC'
                        subtitle='Marketing and Branding'
                        label1='Web Design'
                        image='pixelnew.png'
                    />
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
            <Button
                href="/#contact"
                sx={{
                    minWidth:'100px',
                    marginTop:'5%',
                    width:'10%',
                    // marginLeft:'35%',
                    color: "#89B43D",
                    border: '2px solid #89B43D',
                    '&:hover': {
                        color: "#fff",
                        border: '2px solid #fff'
                    }
                }}
                >
                Next Up?
            </Button>
                </div>
        </Box>
    )
}

export default Showroom