import { Button, IconButton, Paper, Typography } from "@mui/material"
import './../styles/styles.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
    return (
        <Paper sx={{ paddingTop: '10px', height: '100px', background: '#202823', borderRadius: 0, marginTop: '100', textAlign: 'center', bottom: 0 }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', }}>
                    <div style={{ margin: '5px', color: '#fff' }}>
                        <Typography color={'#fff'}>&copy;BoldWebNYC {new Date().getFullYear()}</Typography>
                    </div>
                    <div style={{ margin: '5px', color: '#fff' }}>
                        <Typography color={'#fff'}><a style={{ color: '#fff' }} href="/">Privacy Policy</a> | <a style={{ color: '#fff' }} href="/">Terms</a></Typography>
                    </div>
                    <div style={{ width: '30%' }}>
                        <IconButton>
                            <InstagramIcon sx={{ margin: '5px', color: "#fff" }} />
                        </IconButton>
                        <IconButton>
                            <LinkedInIcon sx={{ margin: '5px', color: "#fff" }} />
                        </IconButton>
                        <IconButton>
                            <FacebookIcon sx={{ margin: '5px', color: "#fff" }} />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div style={{ display: 'block' }}>
                <Button sx={{ color: "#fff" }} color="secondary" variant="text" title="About" />
            </div>
        </Paper>
    )
}

export default Footer