import HomePage from "../components/HomePage"
import About from "../pages/About"
import ContactDetails from "../pages/ContactDetails"
import Home from "../pages/Home"
import Process from "../pages/Process"
import Showroom from "../pages/Showroom"
import Team from "../pages/TeamNew"

const routes = () => {
    return [
        {
            id: '0',
            label: 'Home',
            link: '/',
            component: <Home />,
        },
        // {
        //     id: '1',
        //     label: 'Construction',
        //     link: '/construction',
        //     component: <HomePage />,
        // },
        // {
        //     id: '2',
        //     label: 'Contact',
        //     link: '/contact',
        //     component: <ContactDetails />,
        // },
        // {
        //     id: '3',
        //     label: 'About',
        //     link: '/about',
        //     component: <About />,
        // },
        // {
        //     id: '4',
        //     label: 'Team',
        //     link: '/team',
        //     component: <Team />,
        // },
        // {
        //     id: '5',
        //     label: 'Process',
        //     link: '/process',
        //     component: <Process />
        // },
        {
            id: '6',
            label: 'Showroom',
            link: '/showroom',
            component: <Showroom />
        }
    ]
}

export default routes 