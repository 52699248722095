import { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuItem, MenuList, Popover, Toolbar } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import './../styles/styles.css';
import MenuIcon from '@mui/icons-material/Menu';

function AppHeader() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState()

    const handleOpen = (e: any) => {
        setAnchorEl(e.currentTarget)
        setOpen(true)
    }

    return (
        <Box className='main' style={{ background: '#fff', borderRadius: 0 }}>
            <Toolbar sx={{ backgroundColor: 'transparent', height: '85px' }}>
                <div style={{ flexGrow: 1 }}>
                    <img onClick={() => navigate('/')} className='headerImg' src='IconWithText.png' alt='IconWithText' />
                </div>

                <Box className='miniMenu'>
                    <IconButton onClick={(e) => handleOpen(e)}>
                        <MenuIcon fontSize='large' color='primary' />
                    </IconButton>
                    <Popover open={open} onClose={() => setOpen(false)} anchorEl={anchorEl}>
                        <MenuList sx={{ color: '#fff' }}>
                            <MenuItem onClick={() => navigate('/#about')}>
                                About Us
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/showroom')}>
                                Showroom
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/#process')}>
                                Our Proccess
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/#contact')}>
                                Contact
                            </MenuItem>
                        </MenuList>
                    </Popover>
                </Box>

                <Box className='largeMenu'>
                    <Button variant='text' href='/#about' sx={{ fontSize: '25px', padding: '5px 10px', fontFamily: 'Romance' }}>
                        About Us
                    </Button>
                    <Button href='/#showroom' variant='text' sx={{ fontSize: '25px', padding: '5px 10px', fontFamily: 'Romance' }}>
                        Showroom
                    </Button>
                    {/* <Button onClick={() => navigate('/team')} variant='text' sx={{ fontSize: '25px', padding: '5px 10px', fontFamily: 'Romance' }}>
                        Our Team
                    </Button>*/}
                    <Button variant='text' href='/#process' sx={{ fontSize: '25px', padding: '5px 10px', fontFamily: 'Romance' }}>
                        The Process
                    </Button>
                    <Button
                        className='button'
                        href='/#contact'
                        variant='contained'
                        sx={{
                            fontSize: '25px',
                            fontFamily: 'Romance',
                            padding: '5px 10px',
                            width: '125px',
                            borderRadius: '25px',
                            transition: '1s',
                            '&:hover': {
                                backgroundColor: '#89B43D',
                                borderRadius: '5px',
                                color: 'tranparent',
                                width: '125px',
                                fontSize: '25px',
                                boxShadow: '2px'
                            }
                        }}
                    >
                        Contact
                    </Button>
                </Box>
            </Toolbar>
        </Box>
    );
}

export default AppHeader;