import { Box, Paper } from '@mui/material';
import './../styles/styles.css';
import { useEffect } from 'react';

function HomePage() {
    return (
            <Paper>
                <img className='logoImg' sizes='large' rel='logo' src='banner.png'/>
                <img className='logoImgSmall' sizes='large' rel='logo' src='bannerSmall.png'/>
            </Paper>
  );
}

export default HomePage;
