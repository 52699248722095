import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import HomePage from '../components/HomePage';
import Footer from '../components/AppFooter';
import routes from '../Routes';
import Contact from '../components/Contact';
import './../styles/styles.css';

const Layout = () => {
    return (
        <div className='master' style={{ margin: "auto" }}>
            <AppHeader />
            <Box style={{ background: 'inherit' }}>
                <Routes>
                    {routes().map((route: any, index: any) => {
                        return (
                            <Route
                                key={route.id}
                                path={route.link}
                                element={route.component}
                            />
                        );
                    })}
                </Routes>
            </Box>
            <Footer />

        </div>
    );
}

export default Layout;