import HomePage from "../components/HomePage"
import About from "./About"
import Contact from "../components/Contact"
import './../styles/styles.css';
import Process from "./Process";
import Showroom from "./Showroom";
import { Button } from "@mui/material";

const Home = () => {
    return (
        <div>
            <HomePage />
            <div id="about">
                <About />
            </div>
            <div id="process">
                <Process />
            </div>
            <div id="showroom">
            <Showroom />
            </div>
            <div id="contact">
                <Contact />
            </div>
        </div>
    )
}

export default Home